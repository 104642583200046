<script lang="ts" setup>
import RfltAccordion from '../RfltAccordion.vue'
import RfltAccordionItem from '../RfltAccordionItem.vue'
import CopyToCliboard from '../CopyToCliboard.vue'

const runtimeConfig = useRuntimeConfig()
const { userActiveTenant, userActiveTenantLoading } = storeToRefs(useTenantStore())

const publicApiTestIntegrationEndpoint = ref(`${runtimeConfig.public.refloatPublicApiUrl}/${runtimeConfig.public.refloatPublicApiLatestVersion}/verify/integration`)

const shikiCodeSnippetOptions = {
  lang: 'javascript',
  tokenizeMaxLineLength: 160,
  theme: 'nord',
}

const triggerUpdateCodeSnippetWithTenantValues = ref<boolean>(false)

const refloatCodeSnippet = ref<string>(
`<!-- Include the Refloat SDK on your page -->
<script>
!function(){
  const a = document.createElement('script');
  a.src = 'https://assets.refloat.com/snippet.js';
  a.async = true;
  const b = document.getElementsByTagName('script')[0];
  b.parentNode.insertBefore(a, b);
}();
<\/script>`,
)

const refloatCodeSnippetInstructions = await useShikiHighlighted(
  refloatCodeSnippet.value,
  { ...shikiCodeSnippetOptions, lang: 'html' },
)

const generateAuthHashEsm = ref<string>(
`import { createHmac } from "node:crypto";
const user_hash = crypto.createHmac(
    "sha256",
    "${userActiveTenant?.value?.client_secret}" // Your Secret Key (keep safe)
)
.update(STRIPE_CUSTOMER_ID) // Replace with actual Stripe Customer ID
.digest("hex"); // Send to front-end
`,
)

const generateAuthHashEsmCodeInstructions = await useShikiHighlighted(
  generateAuthHashEsm.value,
  shikiCodeSnippetOptions,
)

const generateAuthHashCommonJS = ref<string>(
`const { createHmac } = require("node:crypto");
const user_hash = crypto.createHmac(
    "sha256",
    "${userActiveTenant?.value?.client_secret}" // Your Secret Key (keep safe)
)
.update(STRIPE_CUSTOMER_ID) // Replace with actual Stripe Customer ID
.digest("hex"); // Send to front-end
`,
)

const generateAuthHashCommonJSCodeInstructions = await useShikiHighlighted(
  generateAuthHashCommonJS.value,
  shikiCodeSnippetOptions,
)

const generateAuthHashNextJs = ref<string>(
`import crypto from "node:crypto";
import type { NextApiRequest, NextApiResponse } from "next";

type Data = {
  readonly userHash?: string;
  readonly error?: string;
};

export default function handler(
  req: NextApiRequest,
  res: NextApiResponse<Data>
) {
  // Replace with actual Stripe Customer ID
  const { STRIPE_CUSTOMER_ID } = req.body;

  const authHash = crypto
    // Your Secret Key (keep safe)
    .createHmac("sha256", "${userActiveTenant.value?.client_secret}")
    .update(STRIPE_CUSTOMER_ID)
    .digest("hex");

  // Send computed auth hash to front-end application
  return res.status(200).json({ authHash });
}
`,
)

const generateAuthHashNextJsCodeInstructions = await useShikiHighlighted(
  generateAuthHashNextJs.value,
  shikiCodeSnippetOptions,
)

const generateAuthHashPython = ref<string>(
`import hmac
import hashlib

auth_hash = hmac.new(
    "${userActiveTenant.value?.client_secret}", # Your Secret Key (keep safe)
    STRIPE_CUSTOMER_ID, # Replace with actual Stripe Customer ID
    digestmod=hashlib.sha256
).hexdigest() # Send to front-end
`,
)

const generateAuthHashPythonCodeInstructions = await useShikiHighlighted(
  generateAuthHashPython.value,
  { ...shikiCodeSnippetOptions, lang: 'python' },
)

const generateAuthHashRuby = ref<string>(
`OpenSSL::HMAC.hexdigest(
  "sha256",
  "${userActiveTenant.value?.client_secret}", # Your Secret Key (keep safe)
  STRIPE_CUSTOMER_ID # Replace with actual Stripe Customer ID
) # Send to front-end
`,
)

const generateAuthHashRubyCodeInstructions = await useShikiHighlighted(
  generateAuthHashRuby.value,
  { ...shikiCodeSnippetOptions, lang: 'ruby' },
)

const generateAuthHashPhp = ref<string>(
`<?php
echo hash_hmac(
  'sha256',
  STRIPE_CUSTOMER_ID, // Replace with actual Stripe Customer ID
  "${userActiveTenant.value?.client_secret}" // Your Secret Key (keep safe)
);
?>
`,
)

const generateAuthHashPhpCodeInstructions = await useShikiHighlighted(
  generateAuthHashPhp.value,
  { ...shikiCodeSnippetOptions, lang: 'php' },
)

const generateAuthHashGo = ref<string>(
`package main

import (
  "crypto/hmac"
  "crypto/sha256"
  "encoding/hex"
)

func main() {
  hash := hmac.New(
    sha256.New,
    "${userActiveTenant.value?.client_secret}" // Your Secret Key (keep safe)
  )
  hash.Write(STRIPE_CUSTOMER_ID) // Replace with actual Stripe Customer ID
  hex.EncodeToString(hash.Sum(nil))
}
`,
)

const generateAuthHashGoCodeInstructions = await useShikiHighlighted(
  generateAuthHashGo.value,
  { ...shikiCodeSnippetOptions, lang: 'go' },
)

const generateAuthHashJava = ref<string>(
`import javax.crypto.Mac;
import javax.crypto.spec.SecretKeySpec;

public class Test {
  public static void main(String[] args) {
    try {
      String clientSecret = "${userActiveTenant.value?.client_secret}" // Your Secret Key (keep safe)
      String stripeCustomerId = STRIPE_CUSTOMER_ID; // Replace with actual Stripe Customer ID

      Mac sha256_HMAC = Mac.getInstance("HmacSHA256");
      SecretKeySpec secret_key = new SecretKeySpec(clientSecret.getBytes(), "HmacSHA256");
      sha256_HMAC.init(secret_key);

      byte[] hash = (sha256_HMAC.doFinal(stripeCustomerId.getBytes()));
      StringBuffer result = new StringBuffer();
      for (byte b : hash) {
        result.append(String.format("%02x", b));
      }

      // Send to front-end
      System.out.println(result.toString());
    } catch (Exception e) {
      System.out.println("Error");
    }
  }
}
`,
)

const generateAuthHashJavaCodeInstructions = await useShikiHighlighted(
  generateAuthHashJava.value,
  { ...shikiCodeSnippetOptions, lang: 'java' },
)

const spawnRefloatCancelationFlowModal = ref<string>(
  `<body>

  <!-- ... -->

  <button id="refloat-cancel-button">
    Cancel subscription
  </button>

  <!-- ... -->

  <script>
  //  After the DOM is loaded
  document.addEventListener("DOMContentLoaded", () => {
    function spawnModal() {
      window.refloat?.init && window.refloat.init({
        tenantId: "${userActiveTenant?.value?.id}", // Your Refloat Tenant ID
        apiKey: "${userActiveTenant?.value?.api_key}", // Your Refloat API Key
        stripeCustomerId: "", // Replace value with actual Stripe Customer ID
        authHash: "", // Replace value with calculated HMAC hash
      })
    }

    document
      //  Get the button element
      .getElementById('refloat-cancel-button')
      //  Attach the event listener to the button
      .addEventListener('click', spawnModal);
    })
  <\/script>
<\/body>
`,
)

const attachEventListenerToAnEvent = await useShikiHighlighted(
  spawnRefloatCancelationFlowModal.value,
  {
    lang: 'html',
    tokenizeMaxLineLength: 160,
    theme: 'nord',
    mergeWhitespaces: 'never',
  },
)

//  Verify Request Instructions using cURL - placeholder
const verifyRequestInstructionsCURL = ref<string>(
`
export tenant_id="${userActiveTenant?.value?.id}" # Your Refloat Tenant ID
export customer_id="" # Replace value with Stripe Customer ID
export auth_hash="" # Replace value with calculated HMAC hash
export api_key="${userActiveTenant?.value?.api_key}" # Your Refloat API Key

curl "${publicApiTestIntegrationEndpoint.value}" \\
  -d "{
    \"tenant\": \"$tenant_id\",
    \"customerId\": \"$customer_id\",
    \"authHash\": \"$auth_hash\"
  }" \\
  -H "Accept: */*" \\
  -H "Content-Type: application/json" \\
  -H "X-Refloat-Api-Key: $api_key"
`,
)

const verifyRequestInstructionsCurlCodeInstructions = await useShikiHighlighted(
  verifyRequestInstructionsCURL.value,
  {
    lang: 'shell',
    tokenizeMaxLineLength: 160,
    theme: 'nord',
    mergeWhitespaces: 'never',
  },
)

const tenantId = computed(() => userActiveTenant?.value?.id || '')
const apiKey = computed(() => userActiveTenant?.value?.api_key || '')
const secretKey = computed(() => userActiveTenant?.value?.client_secret || '')

watch(tenantId, async () => {
  if (userActiveTenant?.value && !userActiveTenantLoading.value)
    triggerUpdateCodeSnippetWithTenantValues.value = true
}, { once: true, immediate: true })
</script>

<template>
  <section class="account-onboarding__wrap">
    <header class="account-onboarding__step--wrapper">
      <h1 class="account-onboarding__page--header">
        SDK Integration Guide
      </h1>
      <p class="account-onboarding__step--paragraph">
        This guide walks you through the process of integrating the Refloat cancellation flow modal into your platform or website. The modal helps retain customers by offering personalized offers during subscription cancellations. We'll cover everything from placing the script element to handling the cancellation flow event and verifying the request.
      </p>
      <p class="account-onboarding__step--paragraph">
        To ensure <a href="https://www.refloat.com/failed-payment-recovery">Failed Payment Recovery</a> works properly upon release, it's recommended to place the snippet on all pages, rather than just the subscription cancellation page.
      </p>
    </header>

    <!-- Step 1: Insert Script -->
    <div class="account-onboarding__step--wrapper">
      <h2 class="account-onboarding__step--header">
        1. Place the Script Element
      </h2>
      <p class="account-onboarding__step--paragraph">
        The code snippet below will import the Refloat client-side module and assign it to the <code class="rounded bg-gray-100 px-1 py-0.5">window.refloat</code> namespace. This allows you to initialize the Refloat Cancel Flow for your customers later on. Please place this code in the HTML <code class="rounded bg-gray-100 px-1 py-0.5">&lt;head&gt;</code> element.
      </p>
      <p class="account-onboarding__step--paragraph">
        Include the following in your HTML file (this snippet loads Refloat SDK to your page)
      </p>
      <div class="relative">
        <CopyToCliboard :content-to-copy="refloatCodeSnippet" class="absolute right-2 top-2 z-100 hover:bg-gray-800" />
        <div class="code-block relative" v-html="refloatCodeSnippetInstructions" />
      </div>
    </div>

    <!-- Step 2: Compute HMAC -->
    <div class="account-onboarding__step--wrapper">
      <h2 class="account-onboarding__step--header">
        2. Generate Secure HMAC Hash
      </h2>
      <p class="account-onboarding__step--paragraph">
        To ensure secure access, requests need to be authenticated by generating an HMAC hash using the Stripe Customer ID and your Secret Key.
      </p>
      <p class="account-onboarding__step--paragraph">
        Below are examples of how to generate an HMAC hash in various backend languages.
      </p>
      <RfltAccordion class="account-onboarding__step--accordion">
        <RfltAccordionItem>
          <template #title>
            <span class="text-base font-semibold leading-7">
              NodeJS (ESM modules)
            </span>
          </template>
          <div class="relative">
            <CopyToCliboard :content-to-copy="generateAuthHashEsm" class="absolute right-2 top-2 z-100 hover:bg-gray-800" />
            <div class="code-block" v-html="generateAuthHashEsmCodeInstructions" />
          </div>
        </RfltAccordionItem>
        <RfltAccordionItem>
          <template #title>
            <span class="text-base font-semibold leading-7">
              NodeJS (CommonJS)
            </span>
          </template>
          <div class="relative">
            <CopyToCliboard :content-to-copy="generateAuthHashCommonJS" class="absolute right-2 top-2 z-100 hover:bg-gray-800" />
            <div class="code-block" v-html="generateAuthHashCommonJSCodeInstructions" />
          </div>
        </RfltAccordionItem>
        <RfltAccordionItem>
          <template #title>
            <span class="text-base font-semibold leading-7">
              Next.js
            </span>
          </template>
          <div class="relative">
            <CopyToCliboard :content-to-copy="generateAuthHashNextJs" class="absolute right-2 top-2 z-100 hover:bg-gray-800" />
            <div class="code-block" v-html="generateAuthHashNextJsCodeInstructions" />
          </div>
        </RfltAccordionItem>
        <RfltAccordionItem>
          <template #title>
            <span class="text-base font-semibold leading-7">
              Python (Django)
            </span>
          </template>
          <div class="relative">
            <CopyToCliboard :content-to-copy="generateAuthHashPython" class="absolute right-2 top-2 z-100 hover:bg-gray-800" />
            <div class="code-block" v-html="generateAuthHashPythonCodeInstructions" />
          </div>
        </RfltAccordionItem>
        <RfltAccordionItem>
          <template #title>
            <span class="text-base font-semibold leading-7">
              Ruby (Rails)
            </span>
          </template>
          <div class="relative">
            <CopyToCliboard :content-to-copy="generateAuthHashRuby" class="absolute right-2 top-2 z-100 hover:bg-gray-800" />
            <div class="code-block" v-html="generateAuthHashRubyCodeInstructions" />
          </div>
        </RfltAccordionItem>
        <RfltAccordionItem>
          <template #title>
            <span class="text-base font-semibold leading-7">
              PHP
            </span>
          </template>
          <div class="relative">
            <CopyToCliboard :content-to-copy="generateAuthHashPhp" class="absolute right-2 top-2 z-100 hover:bg-gray-800" />
            <div class="code-block" v-html="generateAuthHashPhpCodeInstructions" />
          </div>
        </RfltAccordionItem>
        <RfltAccordionItem>
          <template #title>
            <span class="text-base font-semibold leading-7">
              Go
            </span>
          </template>
          <div class="relative">
            <CopyToCliboard :content-to-copy="generateAuthHashGo" class="absolute right-2 top-2 z-100 hover:bg-gray-800" />
            <div class="code-block" v-html="generateAuthHashGoCodeInstructions" />
          </div>
        </RfltAccordionItem>
        <RfltAccordionItem>
          <template #title>
            <span class="text-base font-semibold leading-7">
              Java
            </span>
          </template>
          <div class="relative">
            <CopyToCliboard :content-to-copy="generateAuthHashJava" class="absolute right-2 top-2 z-100 hover:bg-gray-800" />
            <div class="code-block" v-html="generateAuthHashJavaCodeInstructions" />
          </div>
        </RfltAccordionItem>
      </RfltAccordion>
      <br>
    </div>

    <!-- Step 3: Spawn Modal -->
    <div class="account-onboarding__step--wrapper">
      <h2 class="account-onboarding__step--header">
        3. Trigger the Cancellation Modal (from your web page)
      </h2>
      <p class="account-onboarding__step--paragraph">
        Attach a listener to a button that will trigger the Refloat cancellation flow modal. Below is an example of how to attach a click event listener to a button.
      </p>
      <p class="account-onboarding__step--paragraph">
        Register the event listener for spawning the Refloat cancelation modal
      </p>
      <div class="relative">
        <CopyToCliboard :content-to-copy="spawnRefloatCancelationFlowModal" class="absolute right-2 top-2 z-100 hover:bg-gray-800" />
        <div class="code-block" v-html="attachEventListenerToAnEvent" />
      </div>
    </div>

    <!-- Step 4: Complete Integration -->
    <div class="account-onboarding__step--wrapper">
      <h2 class="account-onboarding__step--header">
        4. Complete the Integration
      </h2>
      <p class="account-onboarding__step--paragraph">
        After adding the necessary script and handlers, when a user clicks the cancellation button, the Refloat SDK will open a modal that presents personalized offers, captures cancellation reasons, and provides insights for reducing churn.
      </p>
    </div>

    <!-- Step 5: Test integration using cURL -->
    <div class="account-onboarding__step--wrapper">
      <h2 class="account-onboarding__step--header">
        5. Test the Integration against Refloat Public API
      </h2>
      <p class="account-onboarding__step--paragraph">
        We have a public API endpoint that you can use to verify your integration. The endpoint will validate the <code class="rounded bg-gray-100 px-1 py-0.5">authHash</code> computed, alongside the other necessary information that should be included in the body of the request (<code class="rounded bg-gray-100 px-1 py-0.5">tenant</code> and <code class="rounded bg-gray-100 px-1 py-0.5">customerId</code>) and the <code class="rounded bg-gray-100 px-1 py-0.5">X-Refloat-Api-Key</code> present in the request header. Refloat Public API will return a success message, with status 200, if the supplied information in the request is valid.
      </p>
      <p class="account-onboarding__step--paragraph">
        Test the integration using cURL
      </p>
      <div class="relative">
        <CopyToCliboard :content-to-copy="verifyRequestInstructionsCURL" class="absolute right-2 top-2 z-100 hover:bg-gray-800" />
        <div class="code-block" v-html="verifyRequestInstructionsCurlCodeInstructions" />
      </div>
    </div>

    <!-- API Credentials reference -->
    <div class="account-onboarding__step--wrapper">
      <h2 class="account-onboarding__step--header">
        API Credentials
      </h2>
      <p class="account-onboarding__step--paragraph">
        For your reference, here are your API credentials (which have been used to populate the code examples above):
      </p>
      <br>
      <div class="grid grid-cols-1 mt-4 gap-4 sm:grid-cols-3">
        <div>
          <label class="relative text-gray-700">
            Your Refloat Tenant ID
            <input
              type="text"
              disabled
              class="mt-2 w-full border border-gray-300 rounded-md bg-gray-200 px-4 py-2 text-gray-700 font-500 focus:border-rfblue-600 focus:ring-rfblue-600"
              :value="userActiveTenant?.id"
            >
            <CopyToCliboard :content-to-copy="tenantId" class="absolute right-0 z-100 -bottom-2 hover:bg-gray-300" />
          </label>
        </div>
        <div>
          <label class="relative text-gray-700">
            Secret Key
            <input
              type="text"
              disabled
              class="mt-2 w-full border border-gray-300 rounded-md bg-gray-200 px-4 py-2 text-gray-700 font-500 focus:border-rfblue-600 focus:ring-rfblue-600"
              :value="userActiveTenant?.client_secret"
            >
            <CopyToCliboard :content-to-copy="secretKey" class="absolute right-0 z-100 -bottom-2 hover:bg-gray-300" />
          </label>
        </div>
        <div>
          <label class="relative text-gray-700">
            API Key
            <input
              type="text"
              disabled
              class="mt-2 w-full border border-gray-300 rounded-md bg-gray-200 px-4 py-2 text-gray-700 font-500 focus:border-rfblue-600 focus:ring-rfblue-600"
              :value="userActiveTenant?.api_key"
            >
            <CopyToCliboard :content-to-copy="apiKey" class="absolute right-0 z-100 -bottom-2 hover:bg-gray-300" />
          </label>
        </div>
      </div>
    </div>

    <!-- Footer -->
    <footer class="text-left">
      <p class="text-md mt-12 text-gray-600">
        Need help?
        <br>
        Don’t hesitate to reach out if you have any questions, need assistance, or have any feedback.
      </p>
    </footer>
  </section>
</template>

<style lang="scss">
.account-onboarding__wrap {
  @apply mx-auto max-w-full p-6 space-y-12;
}
.account-onboarding__step--wrapper {
  @apply space-y-4;
}
.account-onboarding__step--accordion {
  @apply block -mt-12 mb-0 pb-0;
  margin-top: -12px !important;
}
h1.account-onboarding__page--header {
  @apply text-3xl text-rfblue-900 font-bold leading-8 mb-12;
}
h2.account-onboarding__step--header {
  @apply text-2xl text-rfblue-900 font-semibold my-4;
}
p.account-onboarding__step--paragraph {
  @apply text-md mt-2 text-gray-600;
}
div.code-block {
  @apply mt-4 rounded-lg bg-[#2e3440ff] p-4 whitespace-break-spaces;

  pre.shiki > code {
    @apply whitespace-break-spaces;
  }
}
</style>
